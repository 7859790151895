<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'EmptyLayout',
};
</script>
<style lang="less">
@import '@/styles/index.less';
body {
  background-color: #020822;
}
</style>
